// log
import store from "../store";
let account = '0x0';
if (window.ethereum) {
  window.ethereum.enable();
  const accounts = window.ethereum.request({ method: 'eth_requestAccounts' });
  account = accounts[0];


  window.ethereum.on('accountsChanged', function (accounts) {
    // Time to reload your interface with accounts[0]!
    account = accounts[0];
  });
}
const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = (account) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();
      let lastFreeMint = await store
        .getState()
        .blockchain.smartContract.methods.lassssstFreeMint(account)
        .call();
      let nbMint = await store
        .getState()
        .blockchain.smartContract.methods.nbMint(account)
        .call();
      let owner = await store
        .getState()
        .blockchain.smartContract.methods.owner()
        .call();
      let started = await store
        .getState()
        .blockchain.smartContract.methods._sssssssssstarted()
        .call();

      // let cost = await store
      //   .getState()
      //   .blockchain.smartContract.methods.cost()
      //   .call();
      dispatch(
        fetchDataSuccess({
          totalSupply,
          lastFreeMint,
          account,
          nbMint,
          owner,
          started
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
