import React from 'react';

const Scene_2 = () => {
    return (
        <>
            <div className="w-100 h-100 container text_roadmap">
                <h2>ROADMAP V2</h2>
                <p> ✅ - Implementation of version 2.0 of the site</p>
                <p> ✅ -  Implementation of a mini game so you can discover the unique snakes among the unique</p>
                <p> -  Mint of 10k SIYW</p>
                <p> -  We are looking for and want to set up a collaboration with another NFT artist to create other mini games to create hype. </p>
                <p> -   When the trade volume is 75 ETH on the SIYW collection, we will distribute 10 hand embroidered hoodies to 10 different Hodlers. Each Hodler will be able to choose a SIYW to embroider from their wallet
                    (We will organize the contest in the form of a draw, giveway, and by participating in the life of the project)</p>
                <p> -  Once we have reached 100 ETH of trade volume, we will set up a consensus to create other mini-games, distribution of rewards, goodies...</p>
                <p> -   The fact that the project is growing will allow us to devote more time to a better development of the user experience.
                    The project will constantly evolve, community participation will be rewarded</p>
            </div>
            <div className="winter_scene">
                <div className="winter_scene_bg " />
                <div className='faq'>
                    <div className='row'>
                        <div className='col-6'>
                            <div className="btn-team-pos">
                                <div className="team-group">
                                    <button className="btn-team" disabled>
                                        Made with 💚 by
                                    </button>
                                    <a href='https://mydev.fr' target={"_blank"} className="btn-team-shadow">myDev.fr</a>
                                </div>
                            </div>
                        </div>
                        <div className='col-6'>

                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed btn-retro accordion-button-one" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                            HOW CAN I BUY A SNAKE?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            - You can buy your own Snake(s) by minting it/them via our
                                            website in the section "MINT"
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingTwo">
                                        <button className="accordion-button collapsed btn-retro accordion-button-two" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                            HOW MUCH DOES A SNAKE COST?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            - The price of 1 Snake is 0 ETH (FREE!) (+ gas fees) per
                                            24H.<br />
                                            - More cost 0.007 ETH<br />
                                            - There is a maximum of 10 Snakes per wallet.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingThree">
                                        <button className="accordion-button collapsed btn-retro accordion-button-tree" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                            WHAT IS THE TOTAL SIYW SUPPLY?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            A total of 10,000 Snakes will be created and ready to be
                                            minted via our website.<br />
                                            – Up to 500 snakes will be reserved for the creator,
                                            helpers, giveaways, raffles and competitions.<br />
                                            – The remaining 9,500 will be available for the public mint.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingFour">
                                        <button className="accordion-button collapsed btn-retro accordion-button-four" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                            WILL THERE BE A PRE-SALE OR WHITELIST?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            There won’t be any pre-sale or whitelist.<br />
                                            – Fair distribution is the keyword: All Snakes will cost the
                                            same price and be released at the same time.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingFive">
                                        <button className="accordion-button collapsed btn-retro accordion-button-five" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                            ARE THERE SOCIAL NETWORKS ?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            Yep, only Twitter at this time
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <img className="winter_star" id="winter_star" src="./img/story/winter/l2_stars.png" alt="stars" />
                <img className="winter_clouds_2" id="winter_clouds_2" src="./img/story/winter/l4_clouds02.png" alt="cloud" />

                <img className="winter_mountain" id="winter_mountain" src="./img/story/winter/l5_mountains.png" alt="mountain" />
                <div className="winter_clouds_1" id="winter_clouds_1" />

                <img className="winter_ground_1" id="winter_ground_1" src="./img/story/winter/l6_ground01.png" alt="ground" />

                <img className="winter_ground_2" id="winter_ground_2" src="./img/story/winter/l7_ground02.png" alt="mountain" />

                <img className="winter_trees" id="winter_trees" src="./img/story/winter/l8_trees.png" alt="ground" />
                <div className="moon" />
                <div className="ground_winter">
                    <div className="slider-inverse">
                        <div className="slide-track-2">
                            <div className="slide_winter slide_winter_one">
                                <img src="img/snakes_gif/9.gif" alt="pic" />
                            </div>
                            <div className="slide_winter slide_winter_two">
                                <img src="img/snakes_gif/10.gif" alt="pic" />
                            </div>
                            <div className="slide_winter slide_winter_one">
                                <img src="img/snakes_gif/1.gif" alt="pic" />
                            </div>
                            <div className="slide_winter slide_winter_tree">
                                <img src="img/snakes_gif/13.gif" alt="pic" />
                            </div>
                            <div className="slide_winter slide_winter_one">
                                <img src="img/snakes_gif/2.gif" alt="pic" />
                            </div>
                            <div className="slide_winter slide_winter_tree">
                                <img src="img/snakes_gif/3.gif" alt="pic" />
                            </div>
                            <div className="slide_winter slide_winter_tree">
                                <img src="img/snakes_gif/14.gif" alt="pic" />
                            </div>
                            <div className="slide_winter slide_winter_two">
                                <img src="img/snakes_gif/4.gif" alt="pic" />
                            </div>
                            <div className="slide_winter slide_winter_tree">
                                <img src="img/snakes_gif/5.gif" alt="pic" />
                            </div>
                            <div className="slide_winter slide_winter_tree">
                                <img src="img/snakes_gif/15.gif" alt="pic" />
                            </div>
                            <div className="slide_winter slide_winter_one">
                                <img src="img/snakes_gif/16.gif" alt="pic" />
                            </div>
                            <div className="slide_winter slide_winter_one">
                                <img src="img/snakes_gif/6.gif" alt="pic" />
                            </div>
                            <div className="slide_winter slide_winter_two">
                                <img src="img/snakes_gif/7.gif" alt="pic" />
                            </div>
                            <div className="slide_winter slide_winter_two">
                                <img src="img/snakes_gif/8.gif" alt="pic" />
                            </div>
                        </div>
                    </div>
                </div>
                <img className="winter_star_2" id="winter_star_2" src="./img/story/winter/l2_stars.png" alt="stars" />
            </div>
        </>

    );
};

export default Scene_2;