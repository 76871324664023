import React from 'react';

const Scene_4 = () => {
  return (
    <section className="uniques_gifs">
      <button type="button" className="close_game btn-close btn-close-white" aria-label="Close" />
      <div className="row">
        <div className="col-12 text-center">
          <div id="picbox">
            <span id="boxbuttons">
              <div className="button">
                <span id="counter">0</span>
                Clicks
              </div>
              <div className="button">
                {/* <a onClick={{ ResetGame }}>Reset</a> */}
                <a onclick="ResetGame();">Reset</a>
              </div>
              <div id="twitter" className="mt-5" />
            </span>
            <div id="boxcard" className="justify-content-center" />
          </div>
        </div>
      </div>
      {/* <div class="row">
    <div class="col-2 mb-3">
      <img class="img-fluid" src="./img/uniques/1.gif" alt="unique 1" />
    </div>
    <div class="col-2">
      <img class="img-fluid" src="./img/uniques/2.gif" alt="unique 2" />
    </div>
    <div class="col-2">
      <img class="img-fluid" src="./img/uniques/3.gif" alt="unique 2" />
    </div>
    <div class="col-2">
      <img class="img-fluid" src="./img/uniques/4.gif" alt="unique 2" />
    </div>
    <div class="col-2">
      <img class="img-fluid" src="./img/uniques/5.gif" alt="unique 2" />
    </div>
    <div class="col-2"></div>
    <div class="col-2">
      <img class="img-fluid" src="./img/uniques/6.gif" alt="unique 2" />
    </div>
    <div class="col-2">
      <img class="img-fluid" src="./img/uniques/7.gif" alt="unique 2" />
    </div>
    <div class="col-2">
      <img class="img-fluid" src="./img/uniques/8.gif" alt="unique 2" />
    </div>
    <div class="col-2">
      <img class="img-fluid" src="./img/uniques/9.gif" alt="unique 2" />
    </div>
    <div class="col-2">
      <img class="img-fluid" src="./img/uniques/10.gif" alt="unique 2" />
    </div>
  </div> */}
    </section>

  );
};

export default Scene_4;