import React from 'react';

const Scene_1 = () => {
    return (
        <section className="scene-1 bg-pink" id="scene_one">
            <header id="header">
                <div className="btn-content-1-pos">
                    <div className="content-1-group">
                        <button className="btn-content-1" disabled>
                            10,000 NFT's
                        </button>
                        <span className="btn-content-1-shadow">Each one is unique</span>
                    </div>
                    <span className="btn-content-1-shadow-2" id="btn-content-2">ERC721A contract <small>for less gas fees</small></span>
                </div>
                <div className="decor">
                    <img className="mountains" id="mountain" src="./img/story/nature/l2_mountains.png" alt="mountain" />
                    <img className="clouds" src="./img/story/nature/l3_clouds.png" alt="clouds" />
                    <img className="ground_1" src="./img/story/nature/l4_bg-ground01.png" alt="ground_1" />
                    <img className="ground_2" src="./img/story/nature/l5_bg-ground02.png" alt="ground_2" />
                    <img className="trees" src="./img/story/nature/l6_trees.png" alt="tree" />
                    <div className="ground">
                        <div className="slider-2">
                            <div className="slide-track-2">
                                <div className="slide">
                                    <img src="img/snakes_gif/1.gif" alt="snake 1" />
                                </div>
                                <div className="slide">
                                    <img src="img/snakes_gif/9.gif" alt="snake 2" />
                                </div>
                                <div className="slide">
                                    <img src="img/snakes_gif/3.gif" alt="snake 3" />
                                </div>
                                <div className="slide">
                                    <img src="img/snakes_gif/10.gif" alt="snake 4" />
                                </div>
                                <div className="slide">
                                    <img src="img/snakes_gif/5.gif" alt="snake 5" />
                                </div>
                                <div className="slide">
                                    <img src="img/snakes_gif/11.gif" alt="snake 1" />
                                </div>
                                <div className="slide">
                                    <img src="img/snakes_gif/7.gif" alt="snake 2" />
                                </div>
                                <div className="slide">
                                    <img src="img/snakes_gif/12.gif" alt="snake 3" />
                                </div>
                                <div className="slide">
                                    <img src="img/snakes_gif/1.gif" alt="snake 1" />
                                </div>
                                <div className="slide">
                                    <img src="img/snakes_gif/9.gif" alt="snake 2" />
                                </div>
                                <div className="slide">
                                    <img src="img/snakes_gif/3.gif" alt="snake 3" />
                                </div>
                                <div className="slide">
                                    <img src="img/snakes_gif/10.gif" alt="snake 4" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="decor_two">
                    <div className="cloud_pyramid" id="cloud_pyramid" />
                    <img className="pyramid" id="pyramid" src="./img/story/pyramids/l3_pyramid.png" alt="pyramid" />
                    <img className="pyramid_ground_1" id="pyramid_ground_1" src="./img/story/pyramids/l4_bg-ground01.png" alt="pyramid ground" />
                    <img className="pyramid_ground_2" id="pyramid_ground_2" src="./img/story/pyramids/l5_bg-ground02.png" alt="pyramid ground" />
                    <img className="pyramid_ground_3" id="pyramid_ground_3" src="./img/story/pyramids/l6_bg-ground03.png" alt="pyramid ground" />
                    <img className="trees_pyramid" src="./img/story/pyramids/l6_trees.png" alt="tree" />
                    <div className="ground_pyramid">
                        <div className="slider-inverse">
                            <div className="slide-track-2">
                                <div className="slide_pyramid">
                                    <img src="img/snakes_gif/9.gif" alt="snake 1" />
                                </div>
                                <div className="slide_pyramid">
                                    <img src="img/snakes_gif/10.gif" alt="snake 2" />
                                </div>
                                <div className="slide_pyramid">
                                    <img src="img/snakes_gif/11.gif" alt="snake 3" />
                                </div>
                                <div className="slide_pyramid">
                                    <img src="img/snakes_gif/12.gif" alt="snake 4" />
                                </div>
                                <div className="slide_pyramid">
                                    <img src="img/snakes_gif/2.gif" alt="snake 5" />
                                </div>
                                <div className="slide_pyramid">
                                    <img src="img/snakes_gif/14.gif" alt="snake 1" />
                                </div>
                                <div className="slide_pyramid">
                                    <img src="img/snakes_gif/4.gif" alt="snake 2" />
                                </div>
                                <div className="slide_pyramid">
                                    <img src="img/snakes_gif/16.gif" alt="snake 3" />
                                </div>
                                <div className="slide_pyramid">
                                    <img src="img/snakes_gif/3.gif" alt="snake 1" />
                                </div>
                                <div className="slide_pyramid">
                                    <img src="img/snakes_gif/15.gif" alt="snake 2" />
                                </div>
                                <div className="slide_pyramid">
                                    <img src="img/snakes_gif/13.gif" alt="snake 3" />
                                </div>
                                <div className="slide_pyramid">
                                    <img src="img/snakes_gif/7.gif" alt="snake 4" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="kawai">
                    <svg className="kawai" fill="#f9d71c" style={{ fill: '#f9d71c !important' }} viewBox="0 0 2605 2460" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1302.5 1230.6 1 1308.4l1.5-176.6 1300 97v1.8Z" />
                        <path d="M1302.2 1230.5 30 960.3l53.7-169.1 1219.2 437.7-.6 1.6Z" />
                        <path d="M1302 1230.4 160.8 634l101.8-148.1L1303 1229l-1 1.4Z" />
                        <path d="M1301.8 1230.3 383.3 355.2 525 240l778.2 989.1-1.4 1.2Z" />
                        <path d="m1301.6 1230-622-1083.4 170.1-73 453.6 1155.7-1.7.8Z" />
                        <path d="M1301.6 1229.8 1025.8 25l185-25 92.6 1229.6-1.8.2Z" />
                        <path d="M1301.6 1229.6 1394.2 0l185 25-275.8 1204.8-1.8-.2Z" />
                        <path d="M1301.6 1229.3 1755.3 73.6l170.1 73-622 1083.5-1.8-.8Z" />
                        <path d="M1301.8 1229.1 2080 240l141.7 115.2-918.5 875-1.4-1Z" />
                        <path d="m1302 1229 1040.4-743.2 101.8 148L1303 1230.4l-1-1.5Z" />
                        <path d="m1302.2 1228.9 1219.1-437.7 53.8 169.1-1272.3 270.3-.6-1.7Z" />
                        <path d="m1302.5 1228.8 1300-97 1.5 176.6-1301.5-77.8v-1.8ZM1301.5 1228.8 0 1151l1.5 176.6 1300-97v-1.8Z" />
                        <path d="M1301.2 1228.8 29 1499.1l53.7 169.1 1219.2-437.7-.6-1.7Z" />
                        <path d="M1301 1229 159.8 1825.5l101.8 148.2L1302 1230.4l-1-1.4Z" />
                        <path d="m1300.8 1229.1-918.5 875.1L524 2219.4l778.2-989.1-1.4-1.2Z" />
                        <path d="m1300.6 1229.3-622 1083.5 170.1 73L1302.3 1230l-1.7-.8Z" />
                        <path d="m1300.6 1229.6-275.8 1204.8 185 25 92.6-1229.6-1.8-.2Z" />
                        <path d="m1300.6 1229.8 92.6 1229.6 185-25-275.8-1204.8-1.8.2Z" />
                        <path d="m1300.6 1230 453.7 1155.8 170.1-73-622-1083.5-1.8.8Z" />
                        <path d="m1300.8 1230.3 778.2 989.1 141.7-115.2-918.5-875-1.4 1Z" />
                        <path d="m1301 1230.4 1040.4 743.3 101.8-148.1L1302 1229l-1 1.4Z" />
                        <path d="m1301.2 1230.5 1219.1 437.7 53.8-169.1-1272.3-270.3-.6 1.7Z" />
                        <path d="m1301.5 1230.6 1300 97 1.5-176.6-1301.5 77.8v1.8Z" />
                    </svg>
                </div>
            </header>
        </section>

    );
};

export default Scene_1;