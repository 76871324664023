import React from 'react';

const Rs = () => {
    return (
        <div className="rs">
            <a href="https://opensea.io/collection/snakes-in-your-wallet" target="_blank"><img src="./img/OpenSea.png" alt="opensea" /></a>
            <a href="https://twitter.com/SnakesIYWallet" target="_blank"><img src="./img/Twitter.png" alt="twitter" /></a>
        </div>

    );
};

export default Rs;